<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="28.3" height="28.3" viewBox="0 0 28.3 28.3">
  <g id="Group_8909" data-name="Group 8909" transform="translate(0.127)">
    <rect id="Rectangle_1401" data-name="Rectangle 1401" class="cls-1" width="28.3" height="28.3" rx="14.15" transform="translate(-0.127)"/>
    <g id="noun_History_2663060" transform="translate(5.204 6.52)">
      <g id="Group_14443" data-name="Group 14443" transform="translate(-0.012)">
        <path id="Path_9855" data-name="Path 9855" class="cls-2" d="M1.706,6.171a7.52,7.52,0,1,1,.129,3.3.455.455,0,1,1,.88-.231,6.612,6.612,0,1,0-.073-3.124L3.559,5.3a.455.455,0,1,1,.6.682l-1.813,1.6a.455.455,0,0,1-.642-.04L.1,5.736a.455.455,0,1,1,.682-.6L1.7,6.172Z" transform="translate(0.012)"/>
        <path id="Path_9856" data-name="Path 9856" class="cls-2" d="M63.273,17.676a.456.456,0,1,1,.911,0V22.2l2.223,2.223a.457.457,0,1,1-.646.646L63.4,22.713a.46.46,0,0,1-.134-.323V17.676Z" transform="translate(-54.609 -14.863)"/>
      </g>
    </g>
  </g>
</svg>

</template>
<script>
export default {
    name: 'HistoryIcon'
}
</script>
<style lang="less" scoped>
      .cls-1, .cls-2 {
        fill: #21455e;
      }

      .cls-1 {
        opacity: 0.04;
      }
</style>