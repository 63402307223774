<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="28.3" height="28.3" viewBox="0 0 28.3 28.3">
  <g id="Group_8909" data-name="Group 8909" transform="translate(0.127)">
    <rect id="Rectangle_1401" data-name="Rectangle 1401" class="cls-1" width="28.3" height="28.3" rx="14.15" transform="translate(-0.127)"/>
    <g id="noun_admin_2805231" transform="translate(0.873 5.5)">
      <path id="Path_9854" data-name="Path 9854" class="cls-2" d="M17.835,4.228h0a8.428,8.428,0,0,1-4.252-1.481,1.057,1.057,0,0,0-1.358,0A8.594,8.594,0,0,1,7.96,4.228,1.058,1.058,0,0,0,7,5.284c0,7.68,5.253,10.053,5.472,10.149a1.139,1.139,0,0,0,.85,0c.219-.1,5.472-2.469,5.472-10.149A1.058,1.058,0,0,0,17.835,4.228ZM12.884,14.5a7.318,7.318,0,0,1-2.057-1.522l.137-1.193a1.3,1.3,0,0,1,1.289-1.152h1.276a1.293,1.293,0,0,1,1.289,1.152l.137,1.193A6.888,6.888,0,0,1,12.884,14.5ZM12.9,9.4a1.193,1.193,0,1,1,1.193-1.193A1.194,1.194,0,0,1,12.9,9.4Zm2.976,2.482-.027-.219a2.343,2.343,0,0,0-1.4-1.865,2.222,2.222,0,1,0-3.772-1.591A2.2,2.2,0,0,0,11.348,9.8a2.322,2.322,0,0,0-1.4,1.865l-.027.219A11.2,11.2,0,0,1,8.07,5.27a10.046,10.046,0,0,0,4.855-1.714A9.456,9.456,0,0,0,17.739,5.27l.027.027A11.09,11.09,0,0,1,15.874,11.881Z"/>
    </g>
  </g>
</svg>
</template>
<script>
export default {
    name: 'AdminsIcon'
}
</script>
<style lang="less" scoped>
      .cls-1, .cls-2 {
        fill: #21455e;
      }

      .cls-1 {
        opacity: 0.04;
      }

      .cls-2 {
        stroke: #f6f7f9;
        stroke-width: 0.3px;
      }
</style>