<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.3"
        height="28.3"
        viewBox="0 0 28.3 28.3"
    >
        <g id="Group_8903" data-name="Group 8903" transform="translate(0.127)">
            <rect
                id="Rectangle_1401"
                data-name="Rectangle 1401"
                class="cls-1"
                width="28.3"
                height="28.3"
                rx="14.15"
                transform="translate(-0.127)"
            />
            <g id="group" transform="translate(5.873 9)">
                <g
                    id="Group_5050"
                    data-name="Group 5050"
                    transform="translate(0 4.323)"
                >
                    <g id="Group_5049" data-name="Group 5049">
                        <path
                            id="Path_4768"
                            data-name="Path 4768"
                            class="cls-2"
                            d="M29.931,268.65H27.813a2.531,2.531,0,0,0-1.249.329,2.577,2.577,0,0,0-.822.739,2.54,2.54,0,0,0-.438-.038h-2.12a2.54,2.54,0,0,0-.438.038,2.575,2.575,0,0,0-.822-.739,2.531,2.531,0,0,0-1.249-.329H18.556a2.557,2.557,0,0,0-2.551,2.551v1.438a.274.274,0,0,0,.274.272h4.346v.756a.274.274,0,0,0,.274.274h6.682a.274.274,0,0,0,.274-.274v-.756H32.2a.274.274,0,0,0,.274-.274V271.2A2.557,2.557,0,0,0,29.931,268.65Zm-9.278,3.335v.378h-4.1V271.2a2.009,2.009,0,0,1,2-2h2.118a1.984,1.984,0,0,1,.979.257,2.034,2.034,0,0,1,.531.433A2.564,2.564,0,0,0,20.653,271.985Zm6.648.246v1.164h-6.12V271.8l.011-.045.019-.068.013-.045a2.014,2.014,0,0,1,1.9-1.383l.055-.03H25.3a2.011,2.011,0,0,1,1.986,1.748.465.465,0,0,0,.011.125Zm4.633.134H27.853v-.794l-.013-.047v-.093l-.026-.074a2.572,2.572,0,0,0-1.391-1.457,2.035,2.035,0,0,1,.41-.446,1.984,1.984,0,0,1,.979-.257h2.12a2.009,2.009,0,0,1,2,2Z"
                            transform="translate(-16.005 -268.65)"
                        />
                    </g>
                </g>
                <g
                    id="Group_5054"
                    data-name="Group 5054"
                    transform="translate(1.678 0.002)"
                >
                    <g id="Group_5053" data-name="Group 5053">
                        <path
                            id="Path_4769"
                            data-name="Path 4769"
                            class="cls-2"
                            d="M54.824,177.737a1.929,1.929,0,1,0,.563,1.364A1.922,1.922,0,0,0,54.824,177.737Zm-.387,2.337a1.381,1.381,0,1,1,.4-.977A1.381,1.381,0,0,1,54.437,180.074Z"
                            transform="translate(-51.529 -177.17)"
                        />
                    </g>
                </g>
                <g
                    id="Group_5060"
                    data-name="Group 5060"
                    transform="translate(10.939)"
                >
                    <g
                        id="Group_5059"
                        data-name="Group 5059"
                        transform="translate(0)"
                    >
                        <path
                            id="Path_4771"
                            data-name="Path 4771"
                            class="cls-2"
                            d="M249.493,177.13a1.929,1.929,0,1,0,1.928,1.931A1.929,1.929,0,0,0,249.493,177.13Zm.975,2.905a1.381,1.381,0,1,1,0-1.953A1.381,1.381,0,0,1,250.468,180.035Z"
                            transform="translate(-247.562 -177.13)"
                        />
                    </g>
                </g>
                <g
                    id="Group_5064"
                    data-name="Group 5064"
                    transform="translate(6.312 1.03)"
                >
                    <g id="Group_5063" data-name="Group 5063">
                        <path
                            id="Path_4772"
                            data-name="Path 4772"
                            class="cls-2"
                            d="M152.913,199.492a1.927,1.927,0,1,0,0,2.726A1.927,1.927,0,0,0,152.913,199.492Zm-1.362,2.75a1.381,1.381,0,1,1,1.382-1.381A1.381,1.381,0,0,1,151.551,202.242Z"
                            transform="translate(-149.625 -198.93)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'ActiveUsersIcon'
};
</script>
<style lang="less" scoped>
.cls-1,
.cls-2 {
    fill: #21455e;
}

.cls-1 {
    opacity: 0.04;
}

.cls-2 {
    stroke: #00acdc;
    stroke-width: 0.1px;
}
</style>
