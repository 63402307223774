<template>
<div>
    <div class="admin" v-if='canAccess'>
        <admin-header :user="user"></admin-header>
        <router-view></router-view>
    </div>
</div>
</template>
<script>
import AdminHeader from "./Admin/AdminHeader";

export default {
    name: "Admin",
    components: {
        AdminHeader
    },
    data: () => ({
        canAccess: false,
        // user: false
    }),
    computed: {
        user: {
            get() {
                return this.$store.state.userData;
            },
            set(){
                
            }
        }
    },
    mounted() {
        this.ifAdmin();
        this.listenToEvents();
    },
    methods: {
        listenToEvents() {
            window.vEvent.listen("admin-common-success", this.commonSuccess);
            window.vEvent.listen("admin-common-error", this.commonError);
        },
        ifAdmin() {
            this.axios.get('admin-check')
                .then(response => {
                    this.canAccess = response.data.is_admin;
                    if (!this.canAccess) {
                        this.$router.push('/404');
                    }
                    this.user = response.data.user;
                }).catch(error => console.log(error));
        },
        commonSuccess({ title, message, close }) {
            this.$notify({
                title: title,
                message: message,
                type: "success",
                position: "bottom-left",
                showClose: close,
                customClass: "admin-notify-success",
                offset: 100
            });
        },
        commonError({ title, message, close }) {
            this.$notify({
                title: title,
                message: message,
                type: "error",
                position: "bottom-left",
                showClose: close,
                duration: 0,
                offset: 100,
                customClass: "admin-notify-error"
            });
        }
    },
    beforeDestroy() {
        window.vEvent.stop("admin-common-success", this.commonSuccess);
        window.vEvent.stop("admin-common-error", this.commonError);
    },
    // watch: {
    //     user(n, o)
    //     {
    //         if (n.super_admin && n.super_admin == 1) this.canAccess = true;
    //         else this.$router.push('/404');
    //     }
    // }
};
</script>

<style lang="less" scoped>
@import "~element-ui/lib/theme-chalk/icon.css";
</style>
<style lang="less">
.admin {
    display: flex;
    flex-direction: column;
    background-color: #f9fbfb;
    align-items: center;
}
</style>
