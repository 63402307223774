<template>
  <div id="admin_menu">
    <el-menu mode="horizontal" class="el-menu-admin" :router="true">
      <div class="left">
        <el-menu-item :key="1" class="logo-item" index="/" style="padding-left:60px;">
          <img alt="Adilo" class="logo" src="../../assets/logo.svg" />
        </el-menu-item>
      </div>
      <div class="center">
        <el-menu-item
          class="admin-menu-item"
          :class="{ 'is-active': activeTabName === 'dashboard' }"
          :to="{ path: '/admin/dashboard' }"
          index="/admin/dashboard"
        >Dashboard</el-menu-item>

        <el-submenu popper-class="admin-submenu" class= "admin-menu-item" index="2">
          <template slot="title">
            <a class="item-users" href="#">Users</a>
          </template>

          <router-link to="/admin/active">
            <el-menu-item class="submenu-users submenu-active-users">
              <div class="submenu-img active-users-img">
                <active-users-icon></active-users-icon>
              </div>
              <div class="active-users-text">Active Users</div>
            </el-menu-item>
          </router-link>

          <el-menu-item class="submenu-users submenu-admins">
            <div class="admins-img submenu-img">
              <admins-icon></admins-icon>
            </div>
            <div class="admins-text">Admins</div>
          </el-menu-item>
          <div class="divided"></div>

          <router-link to="/admin/suspended">
            <el-menu-item class="submenu-users submenu-suspended">
              <div class="suspended-img submenu-img">
                <suspended-users-icon></suspended-users-icon>
              </div>
              <div class="suspended-text">Suspended users</div>
            </el-menu-item>
          </router-link>

          <router-link to="/admin/deleted">
            <el-menu-item class="submenu-users submenu-deleted">
              <div class="deleted-img submenu-img">
                <deleted-users-icon></deleted-users-icon>
              </div>
              <div class="deleted-text">Deleted users</div>
            </el-menu-item>
          </router-link>
        </el-submenu>

        <el-submenu popper-class="admin-submenu" class="admin-menu-item" index="3">
          <template slot="title">
            <a class="item-compliance" href="#">Compliance</a>
          </template>

          <el-menu-item class="submenu-compliance submenu-issues">
            <div class="issues-img submenu-img">
              <issues-icon></issues-icon>
            </div>
            <div class="issues-text">Issues</div>
          </el-menu-item>

          <el-menu-item class="submenu-compliance submenu-history">
            <div class="history-img submenu-img">
              <history-icon></history-icon>
            </div>
            <div class="history-text">History</div>
          </el-menu-item>
        </el-submenu>

        <el-menu-item
          class="admin-menu-item"
          :class="{ 'is-active': activeTabName === 'plans' }"
          :to="{ path: '/admin/plans' }"
          index="/admin/plans"
        >Plans</el-menu-item>
        <el-menu-item
          class="admin-menu-item"
          :class="{ 'is-active': activeTabName === 'analytics' }"
          :to="{ path: '/admin/analytics' }"
          index="/admin/analytics"
        >Analytics</el-menu-item>
        <el-menu-item
          class="admin-menu-item"
          :class="{ 'is-active': activeTabName === 'partners' }"
          :to="{ path: '/admin/partners' }"
          index="/admin/partners"
        >Partners</el-menu-item>
        <el-menu-item
          class="admin-menu-item"
          :class="{ 'is-active': activeTabName === 'transactions' }"
          :to="{ path: '/admin/transactions' }"
          index="/admin/transactions"
        >Transactions</el-menu-item>
      </div>

      <div class="right">
        <el-submenu popper-class="admin-submenu user-submenu" class="profile-menu-item" index="1">
          <template slot="title">
            <div class="user-info">
              <div class="user-name">{{ user.full_name }}</div>
              <div class="user-company">{{ user.company }}</div>
            </div>
            <img
              src="../../assets/img/admin/nopic.png"
              alt
              class="img-circle profile-avatar"
              v-if="user.settings"
              width="42"
            />
          </template>

          <el-menu-item class="submenu-my-account">
            <el-button size="small" class="button-my-account" round>My Account</el-button>
          </el-menu-item>

          <el-menu-item class="submenu-bigcomblog">
            <div class="bigcomblog-img">
              <big-com-blog-icon></big-com-blog-icon>
            </div>
            <div class="bigcomblog-text">Bigcommand Blog</div>
          </el-menu-item>

          <router-link to="/logout">
            <el-menu-item class="submenu-signout" index="logout">
              <div class="signout-img">
                <sign-out-icon></sign-out-icon>
              </div>
              <div class="signout-text">Sign Out</div>
            </el-menu-item>
          </router-link>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>
<script>
import ActiveUsersIcon from "../Admin/SvgComponents/ActiveUsersIcon";
import AdminsIcon from "../Admin/SvgComponents/AdminsIcon";
import DeletedUsersIcon from "../Admin/SvgComponents/DeletedUsersIcon";
import IssuesIcon from "../Admin/SvgComponents/IssuesIcon";
import HistoryIcon from "../Admin/SvgComponents/HistoryIcon";
import BigComBlogIcon from "../Admin/SvgComponents/BigComBlogIcon";
import SignOutIcon from "../Admin/SvgComponents/SignOutIcon";
import SuspendedUsersIcon from "../Admin/SvgComponents/SuspendedUsersIcon";

export default {
  name: "AdminHeader",
  props: {
    user: Object
  },
  components: {
    ActiveUsersIcon,
    AdminsIcon,
    DeletedUsersIcon,
    IssuesIcon,
    HistoryIcon,
    BigComBlogIcon,
    SignOutIcon,
    SuspendedUsersIcon
  },
  data: () => ({
    activeTabName: ""
  })
};
</script>
<style lang="less" src="../../assets/less/custom/admin.less"></style>
<style lang="less"></style>
