<template>
    <svg
        id="Group_8903"
        data-name="Group 8903"
        xmlns="http://www.w3.org/2000/svg"
        width="28.3"
        height="28.3"
        viewBox="0 0 28.3 28.3"
    >
        <rect
            id="Rectangle_1401"
            data-name="Rectangle 1401"
            class="cls-1"
            width="28.3"
            height="28.3"
            rx="14.15"
        />
        <g id="noun_article_1726639" transform="translate(8.872 7.808)">
            <g
                id="Group_8745"
                data-name="Group 8745"
                transform="translate(0 0)"
            >
                <g
                    id="Group_8744"
                    data-name="Group 8744"
                    transform="translate(0 0)"
                >
                    <g
                        id="Group_8743"
                        data-name="Group 8743"
                        transform="translate(0)"
                    >
                        <g id="Group_8737" data-name="Group 8737">
                            <path
                                id="Path_8268"
                                data-name="Path 8268"
                                class="cls-2"
                                d="M23.68,20.875H16.235A1.441,1.441,0,0,1,14.8,19.44V8.935A1.441,1.441,0,0,1,16.235,7.5h4.7l4.2,4.2V19.44A1.465,1.465,0,0,1,23.68,20.875ZM16.235,8.068a.865.865,0,0,0-.867.867v10.5a.865.865,0,0,0,.867.867H23.68a.865.865,0,0,0,.867-.867V11.932L20.683,8.068Z"
                                transform="translate(-14.8 -7.5)"
                            />
                        </g>
                        <g
                            id="Group_8738"
                            data-name="Group 8738"
                            transform="translate(6.309 1.388)"
                        >
                            <path
                                id="Path_8269"
                                data-name="Path 8269"
                                class="cls-2"
                                d="M54.8,16.3v2.445a.182.182,0,0,0,.189.189h2.445Z"
                                transform="translate(-54.8 -16.3)"
                            />
                        </g>
                        <g
                            id="Group_8739"
                            data-name="Group 8739"
                            transform="translate(1.719 4.211)"
                        >
                            <path
                                id="Path_8270"
                                data-name="Path 8270"
                                class="cls-2"
                                d="M25.984,34.2H27.7a.283.283,0,0,1,.284.284h0a.283.283,0,0,1-.284.284H25.984a.283.283,0,0,1-.284-.284h0A.283.283,0,0,1,25.984,34.2Z"
                                transform="translate(-25.7 -34.2)"
                            />
                        </g>
                        <g
                            id="Group_8740"
                            data-name="Group 8740"
                            transform="translate(1.719 6.687)"
                        >
                            <path
                                id="Path_8271"
                                data-name="Path 8271"
                                class="cls-2"
                                d="M25.984,49.9h2.871a.283.283,0,0,1,.284.284h0a.283.283,0,0,1-.284.284H25.984a.283.283,0,0,1-.284-.284h0A.283.283,0,0,1,25.984,49.9Z"
                                transform="translate(-25.7 -49.9)"
                            />
                        </g>
                        <g
                            id="Group_8741"
                            data-name="Group 8741"
                            transform="translate(1.719 8.028)"
                        >
                            <path
                                id="Path_8272"
                                data-name="Path 8272"
                                class="cls-2"
                                d="M25.984,58.4H27.7a.283.283,0,0,1,.284.284h0a.283.283,0,0,1-.284.284H25.984a.283.283,0,0,1-.284-.284h0A.283.283,0,0,1,25.984,58.4Z"
                                transform="translate(-25.7 -58.4)"
                            />
                        </g>
                        <g
                            id="Group_8742"
                            data-name="Group 8742"
                            transform="translate(1.719 9.369)"
                        >
                            <path
                                id="Path_8273"
                                data-name="Path 8273"
                                class="cls-2"
                                d="M25.984,66.9h5.158a.283.283,0,0,1,.284.284h0a.283.283,0,0,1-.284.284H25.984a.283.283,0,0,1-.284-.284h0A.283.283,0,0,1,25.984,66.9Z"
                                transform="translate(-25.7 -66.9)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'BigComBlogIcon'
};
</script>
<style lang="less" scoped>
.cls-1,
.cls-2 {
    fill: #21455e;
}

.cls-1 {
    opacity: 0.04;
}
</style>
