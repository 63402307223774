<template>
    <svg
        id="Group_8906"
        data-name="Group 8906"
        xmlns="http://www.w3.org/2000/svg"
        width="28.3"
        height="28.3"
        viewBox="0 0 28.3 28.3"
    >
        <rect
            id="Rectangle_1401"
            data-name="Rectangle 1401"
            class="cls-1"
            width="28.3"
            height="28.3"
            rx="14.15"
        />
        <g id="noun_logout_1350619" transform="translate(7.589 9.026)">
            <g
                id="Group_8905"
                data-name="Group 8905"
                transform="translate(0 0)"
            >
                <g id="Group_8904" data-name="Group 8904">
                    <path
                        id="Path_8307"
                        data-name="Path 8307"
                        class="cls-2"
                        d="M4.4,11.981a.29.29,0,0,0,.3-.281V1.257c0-.153-.093-.192-.208-.082L.506,4.974c-.277.264-.691-.132-.415-.4L4.778.082a.3.3,0,0,1,.415,0l4.7,4.5c.277.264-.138.659-.415.4l-3.983-3.8c-.116-.111-.208-.073-.208.082V11.7a.288.288,0,0,0,.3.281H9.689a.28.28,0,1,1,0,.559H.294a.28.28,0,1,1,0-.559Z"
                        transform="translate(12.54) rotate(90)"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SignOutIcon'
};
</script>
<style lang="less" scoped>
.cls-1,
.cls-2 {
    fill: #21455e;
}

.cls-1 {
    opacity: 0.04;
}

.cls-2 {
    stroke: #21455e;
    stroke-width: 0.3px;
    fill-rule: evenodd;
}
</style>
