<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.3"
        height="28.3"
        viewBox="0 0 28.3 28.3"
    >
        <g id="Group_8903" data-name="Group 8903" transform="translate(0.127)">
            <rect
                id="Rectangle_1401"
                data-name="Rectangle 1401"
                class="cls-1"
                width="28.3"
                height="28.3"
                rx="14.15"
                transform="translate(-0.127)"
            />
            <g id="information" transform="translate(4.873 5)">
                <g id="Group_14441" data-name="Group 14441">
                    <path
                        id="Path_2217"
                        data-name="Path 2217"
                        class="cls-2"
                        d="M88.223,2.677A9.139,9.139,0,0,0,74.871,15.146c.478.5.316.327.882.882A9.14,9.14,0,0,0,90.9,9.139,9.079,9.079,0,0,0,88.223,2.677ZM87.34,14.719A7.891,7.891,0,0,1,76.18,3.559a7.891,7.891,0,0,1,11.16,11.16Zm0,0"
                        transform="translate(-72.621)"
                    />
                    <g id="Group_14440" data-name="Group 14440">
                        <circle
                            id="Ellipse_4417"
                            data-name="Ellipse 4417"
                            class="cls-3"
                            cx="5"
                            cy="5"
                            r="5"
                            transform="translate(4 4)"
                        />
                        <text
                            id="_"
                            data-name="?"
                            class="cls-4"
                            transform="translate(7 12)"
                        >
                            <tspan x="0" y="0">?</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'IssuesIcon'
};
</script>
<style lang="less" scoped>
.cls-1,
.cls-2,
.cls-3 {
    fill: #21455e;
}

.cls-1 {
    opacity: 0.04;
}

.cls-2 {
    stroke: #f6f7f9;
    stroke-width: 0.4px;
}

.cls-4 {
    fill: #fff;
    font-size: 8px;
    font-family: HelveticaNeue, Helvetica Neue;
}
</style>
