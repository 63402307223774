<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="28.3"
        viewBox="0 0 31 28.3"
    >
        <g
            id="Group_14436"
            data-name="Group 14436"
            transform="translate(-1 -2)"
        >
            <g id="Group_8909" data-name="Group 8909" transform="translate(18)">
                <rect
                    id="Rectangle_1401"
                    data-name="Rectangle 1401"
                    class="cls-1"
                    width="12"
                    height="12"
                    rx="6"
                    transform="translate(2 2)"
                />
                <g
                    id="Group_14438"
                    data-name="Group 14438"
                    transform="translate(6.23 45.963)"
                >
                    <path
                        id="Path_9777"
                        data-name="Path 9777"
                        class="cls-2"
                        d="M0-35.964H.988V-40H0Z"
                    />
                    <path
                        id="Path_9778"
                        data-name="Path 9778"
                        class="cls-2"
                        d="M25.32-35.964h.988V-40H25.32Z"
                        transform="translate(-22.731)"
                    />
                </g>
            </g>
            <g
                id="Group_8949"
                data-name="Group 8949"
                transform="translate(1 2)"
            >
                <path
                    id="Path_8326"
                    data-name="Path 8326"
                    class="cls-3"
                    d="M14.15,0A14.15,14.15,0,1,1,0,14.15,14.15,14.15,0,0,1,14.15,0Z"
                />
                <g id="avatar" transform="translate(-28.199 7)">
                    <g
                        id="Group_8948"
                        data-name="Group 8948"
                        transform="translate(36.073)"
                    >
                        <path
                            id="Path_8324"
                            data-name="Path 8324"
                            class="cls-1"
                            d="M140.144,7.766h.1a2.754,2.754,0,0,0,2.1-.91,5.459,5.459,0,0,0,.937-3.728A3.045,3.045,0,0,0,141.834.421,3.318,3.318,0,0,0,140.228,0h-.051a3.324,3.324,0,0,0-1.606.409,3.047,3.047,0,0,0-1.465,2.719,5.459,5.459,0,0,0,.937,3.728A2.743,2.743,0,0,0,140.144,7.766ZM137.9,3.2c0-.009,0-.018,0-.024a2.171,2.171,0,0,1,2.268-2.37h.036a2.182,2.182,0,0,1,2.268,2.37.058.058,0,0,0,0,.024,4.726,4.726,0,0,1-.737,3.119,1.956,1.956,0,0,1-1.537.639h-.03a1.95,1.95,0,0,1-1.534-.639A4.751,4.751,0,0,1,137.9,3.2Z"
                            transform="translate(-134.067)"
                        />
                        <path
                            id="Path_8325"
                            data-name="Path 8325"
                            class="cls-1"
                            d="M48.331,262.956v-.009c0-.024,0-.048,0-.075a2.341,2.341,0,0,0-1.352-2.414l-.03-.009a8.611,8.611,0,0,1-2.477-1.128.4.4,0,1,0-.463.66,9.3,9.3,0,0,0,2.725,1.245c.7.248.773.991.794,1.671a.6.6,0,0,0,0,.075,5.418,5.418,0,0,1-.063.922,11.918,11.918,0,0,1-10.527,0,5.129,5.129,0,0,1-.063-.922c0-.024,0-.048,0-.075.021-.68.1-1.424.794-1.671a9.38,9.38,0,0,0,2.725-1.245.4.4,0,0,0-.463-.66,8.519,8.519,0,0,1-2.477,1.128l-.03.009a2.345,2.345,0,0,0-1.352,2.414.6.6,0,0,1,0,.075v.009a4.576,4.576,0,0,0,.152,1.352.383.383,0,0,0,.155.188,11.289,11.289,0,0,0,5.826,1.427,11.323,11.323,0,0,0,5.826-1.427.4.4,0,0,0,.155-.188A4.8,4.8,0,0,0,48.331,262.956Z"
                            transform="translate(-36.073 -251.507)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SuspendedUsersIcon'
};
</script>
<style lang="less" scoped>
.cls-1,
.cls-3 {
    fill: #21455e;
}

.cls-2 {
    fill: #fff;
    fill-rule: evenodd;
}

.cls-3 {
    opacity: 0.04;
}
</style>
